/*! modernizr 3.3.1 (Custom Build) | MIT *
 * https://modernizr.com/download/?-csstransforms-csstransforms3d-touchevents-mq-prefixed-prefixedcss-prefixedcssvalue-setclasses !*/
!(function(e, n, t) {
  function r(e, n) {
    return typeof e === n;
  }
  function o() {
    var e, n, t, o, i, s, a;
    for (var f in C)
      if (C.hasOwnProperty(f)) {
        if (((e = []), (n = C[f]), n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)))
          for (t = 0; t < n.options.aliases.length; t++) e.push(n.options.aliases[t].toLowerCase());
        for (o = r(n.fn, 'function') ? n.fn() : n.fn, i = 0; i < e.length; i++)
          (s = e[i]),
            (a = s.split('.')),
            1 === a.length ? (Modernizr[a[0]] = o) : (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), (Modernizr[a[0]][a[1]] = o)),
            y.push((o ? '' : 'no-') + a.join('-'));
      }
  }
  function i(e) {
    var n = x.className,
      t = Modernizr._config.classPrefix || '';
    if ((w && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var r = new RegExp('(^|\\s)' + t + 'no-js(\\s|$)');
      n = n.replace(r, '$1' + t + 'js$2');
    }
    Modernizr._config.enableClasses && ((n += ' ' + t + e.join(' ' + t)), w ? (x.className.baseVal = n) : (x.className = n));
  }
  function s(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function(e, n, t) {
        return n + t.toUpperCase();
      })
      .replace(/^-/, '');
  }
  function a(e) {
    return e
      .replace(/([A-Z])/g, function(e, n) {
        return '-' + n.toLowerCase();
      })
      .replace(/^ms-/, '-ms-');
  }
  function f() {
    return 'function' != typeof n.createElement ? n.createElement(arguments[0]) : w ? n.createElementNS.call(n, 'http://www.w3.org/2000/svg', arguments[0]) : n.createElement.apply(n, arguments);
  }
  function u() {
    var e = n.body;
    return e || ((e = f(w ? 'svg' : 'body')), (e.fake = !0)), e;
  }
  function l(e, t, r, o) {
    var i,
      s,
      a,
      l,
      p = 'modernizr',
      d = f('div'),
      c = u();
    if (parseInt(r, 10)) for (; r--; ) (a = f('div')), (a.id = o ? o[r] : p + (r + 1)), d.appendChild(a);
    return (
      (i = f('style')),
      (i.type = 'text/css'),
      (i.id = 's' + p),
      (c.fake ? c : d).appendChild(i),
      c.appendChild(d),
      i.styleSheet ? (i.styleSheet.cssText = e) : i.appendChild(n.createTextNode(e)),
      (d.id = p),
      c.fake && ((c.style.background = ''), (c.style.overflow = 'hidden'), (l = x.style.overflow), (x.style.overflow = 'hidden'), x.appendChild(c)),
      (s = t(d, e)),
      c.fake ? (c.parentNode.removeChild(c), (x.style.overflow = l), x.offsetHeight) : d.parentNode.removeChild(d),
      !!s
    );
  }
  function p(e, n) {
    return !!~('' + e).indexOf(n);
  }
  function d(n, r) {
    var o = n.length;
    if ('CSS' in e && 'supports' in e.CSS) {
      for (; o--; ) if (e.CSS.supports(a(n[o]), r)) return !0;
      return !1;
    }
    if ('CSSSupportsRule' in e) {
      for (var i = []; o--; ) i.push('(' + a(n[o]) + ':' + r + ')');
      return (
        (i = i.join(' or ')),
        l('@supports (' + i + ') { #modernizr { position: absolute; } }', function(e) {
          return 'absolute' == getComputedStyle(e, null).position;
        })
      );
    }
    return t;
  }
  function c(e, n) {
    return function() {
      return e.apply(n, arguments);
    };
  }
  function m(e, n, t) {
    var o;
    for (var i in e) if (e[i] in n) return t === !1 ? e[i] : ((o = n[e[i]]), r(o, 'function') ? c(o, t || n) : o);
    return !1;
  }
  function v(e, n, o, i) {
    function a() {
      l && (delete O.style, delete O.modElem);
    }
    if (((i = r(i, 'undefined') ? !1 : i), !r(o, 'undefined'))) {
      var u = d(e, o);
      if (!r(u, 'undefined')) return u;
    }
    for (var l, c, m, v, h, g = ['modernizr', 'tspan', 'samp']; !O.style && g.length; ) (l = !0), (O.modElem = f(g.shift())), (O.style = O.modElem.style);
    for (m = e.length, c = 0; m > c; c++)
      if (((v = e[c]), (h = O.style[v]), p(v, '-') && (v = s(v)), O.style[v] !== t)) {
        if (i || r(o, 'undefined')) return a(), 'pfx' == n ? v : !0;
        try {
          O.style[v] = o;
        } catch (y) {}
        if (O.style[v] != h) return a(), 'pfx' == n ? v : !0;
      }
    return a(), !1;
  }
  function h(e, n, t, o, i) {
    var s = e.charAt(0).toUpperCase() + e.slice(1),
      a = (e + ' ' + N.join(s + ' ') + s).split(' ');
    return r(n, 'string') || r(n, 'undefined') ? v(a, n, o, i) : ((a = (e + ' ' + j.join(s + ' ') + s).split(' ')), m(a, n, t));
  }
  function g(e, n, r) {
    return h(e, t, t, n, r);
  }
  var y = [],
    C = [],
    S = {
      _version: '3.3.1',
      _config: { classPrefix: '', enableClasses: !0, enableJSClass: !0, usePrefixes: !0 },
      _q: [],
      on: function(e, n) {
        var t = this;
        setTimeout(function() {
          n(t[e]);
        }, 0);
      },
      addTest: function(e, n, t) {
        C.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function(e) {
        C.push({ name: null, fn: e });
      },
    },
    Modernizr = function() {};
  (Modernizr.prototype = S), (Modernizr = new Modernizr());
  var x = n.documentElement,
    w = 'svg' === x.nodeName.toLowerCase(),
    _ = S._config.usePrefixes ? ' -webkit- -moz- -o- -ms- '.split(' ') : ['', ''];
  S._prefixes = _;
  var b = 'CSS' in e && 'supports' in e.CSS,
    z = 'supportsCSS' in e;
  Modernizr.addTest('supports', b || z);
  var T = (function() {
    var n = e.matchMedia || e.msMatchMedia;
    return n
      ? function(e) {
          var t = n(e);
          return (t && t.matches) || !1;
        }
      : function(n) {
          var t = !1;
          return (
            l('@media ' + n + ' { #modernizr { position: absolute; } }', function(n) {
              t = 'absolute' == (e.getComputedStyle ? e.getComputedStyle(n, null) : n.currentStyle).position;
            }),
            t
          );
        };
  })();
  S.mq = T;
  var P = (S.testStyles = l);
  Modernizr.addTest('touchevents', function() {
    var t;
    if ('ontouchstart' in e || (e.DocumentTouch && n instanceof DocumentTouch)) t = !0;
    else {
      var r = ['@media (', _.join('touch-enabled),('), 'heartz', ')', '{#modernizr{top:9px;position:absolute}}'].join('');
      P(r, function(e) {
        t = 9 === e.offsetTop;
      });
    }
    return t;
  });
  var E = 'Moz O ms Webkit',
    j = S._config.usePrefixes ? E.toLowerCase().split(' ') : [];
  S._domPrefixes = j;
  var k = function(e, n) {
    var t = !1,
      r = f('div'),
      o = r.style;
    if (e in o) {
      var i = j.length;
      for (o[e] = n, t = o[e]; i-- && !t; ) (o[e] = '-' + j[i] + '-' + n), (t = o[e]);
    }
    return '' === t && (t = !1), t;
  };
  S.prefixedCSSValue = k;
  var N = S._config.usePrefixes ? E.split(' ') : [];
  S._cssomPrefixes = N;
  var A = function(n) {
    var r,
      o = _.length,
      i = e.CSSRule;
    if ('undefined' == typeof i) return t;
    if (!n) return !1;
    if (((n = n.replace(/^@/, '')), (r = n.replace(/-/g, '_').toUpperCase() + '_RULE'), r in i)) return '@' + n;
    for (var s = 0; o > s; s++) {
      var a = _[s],
        f = a.toUpperCase() + '_' + r;
      if (f in i) return '@-' + a.toLowerCase() + '-' + n;
    }
    return !1;
  };
  S.atRule = A;
  var L = { elem: f('modernizr') };
  Modernizr._q.push(function() {
    delete L.elem;
  });
  var O = { style: L.elem.style };
  Modernizr._q.unshift(function() {
    delete O.style;
  }),
    (S.testAllProps = h);
  var q = (S.prefixed = function(e, n, t) {
    return 0 === e.indexOf('@') ? A(e) : (-1 != e.indexOf('-') && (e = s(e)), n ? h(e, n, t) : h(e, 'pfx'));
  });
  S.prefixedCSS = function(e) {
    var n = q(e);
    return n && a(n);
  };
  (S.testAllProps = g),
    Modernizr.addTest('csstransforms', function() {
      return -1 === navigator.userAgent.indexOf('Android 2.') && g('transform', 'scale(1)', !0);
    }),
    Modernizr.addTest('csstransforms3d', function() {
      var e = !!g('perspective', '1px', !0),
        n = Modernizr._config.usePrefixes;
      if (e && (!n || 'webkitPerspective' in x.style)) {
        var t,
          r = '#modernizr{width:0;height:0}';
        Modernizr.supports ? (t = '@supports (perspective: 1px)') : ((t = '@media (transform-3d)'), n && (t += ',(-webkit-transform-3d)')),
          (t += '{#modernizr{width:7px;height:18px;margin:0;padding:0;border:0}}'),
          P(r + t, function(n) {
            e = 7 === n.offsetWidth && 18 === n.offsetHeight;
          });
      }
      return e;
    }),
    o(),
    i(y),
    delete S.addTest,
    delete S.addAsyncTest;
  for (var R = 0; R < Modernizr._q.length; R++) Modernizr._q[R]();
  e.Modernizr = Modernizr;
})(window, document);
