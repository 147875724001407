import { getTrackingArray, INTERACTION_DATA, INTERACTION_WEB_GAME_DATA, INTERACTION_VIDEO_GAME_DATA, INTERACTION_FEATURED_DATA } from 'modules/ensighten_tracking/constants/ensightenConstants';

const trackInteraction = ({ type, baseData, overrideData, log }) => {
  const { english_name = 'nvs', englishname = 'nvs', englishshowname = 'nvs', desc = 'nvs', title = 'nvs' } = overrideData;
  let interactionData = null;
  if (type == 'gameClick') {
    interactionData = { ...INTERACTION_WEB_GAME_DATA, ...baseData };
    interactionData.english_name = english_name.toLowerCase();
    interactionData.game_title = english_name.toLowerCase();
    interactionData.franchise = englishshowname.toLowerCase();
    interactionData.content_type = 'featured content';

    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * content_title is clicked content, not current page title
     */
    interactionData.content_title = interactionData.game_title;
  } else if (type == 'videoClick') {
    interactionData = { ...INTERACTION_VIDEO_GAME_DATA, ...baseData };
    interactionData.english_name = 'nvs';
    interactionData.video_title = english_name.toLowerCase();
    interactionData.franchise = englishshowname.toLowerCase();
    interactionData.content_type = 'featured content';
    interactionData.video_category = 'nvs';

    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * content_title is clicked content, not current page title
     */
    interactionData.content_title = interactionData.video_title;
  } else if (type == 'featuredClick') {
    interactionData = { ...INTERACTION_FEATURED_DATA, ...baseData };
    interactionData.franchise = english_name.toLowerCase();
    interactionData.english_name = english_name.toLowerCase();
    interactionData.content_type = `featured content`;
    interactionData.featured_content = desc.toLowerCase();

    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * content_title is clicked content, not current page title
     */

    interactionData.content_title = interactionData.franchise;
  } else if (type == 'appClick') {
    interactionData = { ...INTERACTION_DATA, ...baseData };
    interactionData.english_name = overrideData.nameEnglish;
    interactionData.content_title = overrideData.title;
    interactionData.featured_content = overrideData.nameEnglish;
    interactionData.interaction = 'app store click';
  } else {
    interactionData = { ...INTERACTION_DATA, ...baseData };
    interactionData.franchise = english_name.toLowerCase();
    interactionData.english_name = english_name.toLowerCase();
    interactionData.content_type = `${interactionData.section}: featured content`;

    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * content_title is clicked content, not current page title
     */
    interactionData.content_title = interactionData.franchise;
  }

  interactionData.pageview_event && delete interactionData.pageview_event;

  log.enabled && console.log(`${log.prep} trackInteraction`, log.clr, interactionData);
  getTrackingArray().push({ type: 'interaction', data: interactionData });
};

export default trackInteraction;
