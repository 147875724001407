import request from 'superagent/lib/client';

const URL_BASE = window.TurnerToons.baseURL;
const URL_API = URL_BASE;

const getUrlTrackLike = () => {
  return URL_API + 'track_like';
};
const getUrlTrackUnLike = () => {
  return URL_API + 'track_unlike';
};
const getUrlGetTrackedData = () => {
  return URL_API + 'get_likes';
};

// type: {content type}, id, like_type: {emoji type}
export const trackLikeService = (contentType, entryID, emojiID) => {
  const url = getUrlTrackLike();
  // console.info('trackLikeService | url', url, ' | contentType', contentType, ' | key', entryID, ' | type', emojiID);
  return request
    .post(url)
    .type('form')
    .send({ type: contentType, id: entryID, like_type: emojiID })
    .then((response) => {
      // console.log('%%% trackLikeService', response, '%%%');
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

// type: {content type}, id, uuid, like_type: {emoji type}
//$('.like_button').first().data('id')

export const trackUnlikeService = (contentType, entryID, emojiID, uuid) => {
  const url = getUrlTrackUnLike();
  console.info('trackUnlikeService | url', url, ' | type', contentType, ' | id', entryID, ' | like_type', emojiID, ' | uuid', uuid);
  return request
    .post(url)
    .type('form')
    .send({ type: contentType, id: entryID, like_type: emojiID, uuid })
    .then((response) => {
      // console.log('%%% trackUnlikeService', response, '%%%');
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const getRatingsService = (contentType, entryID) => {
  const baseUrl = getUrlGetTrackedData();
  const url = `${baseUrl}/${contentType}/${entryID}`;

  return request
    .post(url)
    .type('form')
    .then((response) => {
      // console.log('%%% getRatingsService', response, '%%%');
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};
