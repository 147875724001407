export default class TrackViews {
  constructor({ postURL, $viewCounter, type, id }) {
    /**
     * Conversion of Rich's old TrackViews code to ES6
     */
    this.postURL = postURL;
    this.$viewCounter = $viewCounter;
    this.type = type;
    this.id = id;

    this.init();
  }

  init() {
    $.ajax({
      url: this.postURL,
      method: 'POST',
      dataType: 'json',
      data: {
        id: this.id,
        type: this.type,
      },
    })
      .done((response) => {
        if (response > 0) {
          this.renderViews(response);
        } else {
          /**
           * something has gone wrong as response from
           * server should always be greater than 1
           */
          console.warn('TrackViews : postURL', this.postURL, 'response', response);
        }
      })
      .fail((error) => {
        // failed
        console.error('TrackViews : postURL', this.postURL, 'error', error.statusText);
        this.$viewCounter.remove();
      });
  }

  renderViews(count) {
    const formatted = this._format(count);
    this.$viewCounter.find('.count').text(formatted);
  }

  _format(num) {
    const si = [
      { value: 1e18, symbol: 'E' },
      { value: 1e15, symbol: 'P' },
      { value: 1e12, symbol: 'T' },
      { value: 1e9, symbol: 'G' },
      { value: 1e6, symbol: 'M' },
      { value: 1e3, symbol: 'k' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    num = parseFloat(num);
    const digits = num > 100000 && num < 1000000 ? 0 : 1;
    for (i = 0; i < si.length; i++) {
      if (num >= si[i].value) {
        return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
      }
    }
    return (num + 0).toFixed(digits).replace(rx, '$1');
  }
}
