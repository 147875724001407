export const TURNER_METADATA = window.turner_metadata || false;

export const parseData = (data) => {
  // Needs to convert data to lowercase
  // I think it aslo needs to remove certain characters / not sure what tho at the moment
  return (data || '').toLowerCase();
};

export const getTrackingArray = () => {
  return window.turner_metadata && window.turner_metadata.trackAction ? window.turner_metadata.trackAction : [];
};

export const getDomain = () => {
  const hostname = window.location.hostname.replace('www.', '');
  const pathname = window.location.pathname;
  const domain = hostname + pathname;
  return parseData(domain);
};

export const INTERACTION_DATA = {
  interaction: 'internal campaign click',
  interaction_event: 1,
  internalcampaign_event: 1,
  internalcampaign: 'nvs',
};

export const INTERACTION_FEATURED_DATA = {
  interaction: 'featured thumbnail click',
  featured_content: '',
  featuredcontent_event: 1,
  interaction_event: 1,
  contentclick_event: 1,
};

export const INTERACTION_WEB_GAME_DATA = {
  english_name: '',
  interaction: 'game content click',
  interaction_event: 1,
  contentclick_event: 1,
  gameclicked_event: 1,
};

export const INTERACTION_VIDEO_GAME_DATA = {
  english_name: '',
  interaction: 'video content click',
  interaction_event: 1,
  contentclick_event: 1,
  videoclicked_event: 1,
};

export const AD_TEMPLATE = {
  ad_id: '',
  ad_duration: 0,
  ad_type: 'preroll',
};

export const GAMEPLAY_TEMPLATE = {
  game_title: '',
  game_category: '',
  english_name: '',
  gamedetailview_event: 1,
};

export const GAMECATEGORY_TEMPLATE = {
  game_category: '',
  pageview_event: 1,
  gamecategorypage_event: 1,
};

export const VIDEO_PLAYER_DATA = {
  content_type: 'clip',
  content_id: '',
  content_duration: 0,
  content_name: '',
  content_showName: 'boomerang',
  page_name: '',
  section: 'shows',
  subsection: 'boomerang',
  franchise: 'boomerang',
  friendlyName: '',
  market_region: '',
  market: '',
  brand: '',
};

export const AD_DATA = {
  ad_id: '',
  ad_duration: 0,
  ad_type: 'preroll',
};
