import trackInteraction from 'modules/ensighten_tracking/interaction/ensightenInteraction';
import { trackGameCategoryPageview, trackGamePageview, trackPageview } from 'modules/ensighten_tracking/page/ensightenPageview';
import { getContentData } from 'modules/ensighten_tracking/utils';
import { getLogOptions } from 'constants/logging';

const log = getLogOptions({ id: 'tracking' });

const trackingIsEnabled = () => {
  const cookiePolicy = window.TurnerToons && window.TurnerToons.COOKIE_POLICY ? window.TurnerToons.COOKIE_POLICY : {};
  const oneTrustActive = cookiePolicy.oneTrustActive || false;
  const performanceCookiesEnabled = cookiePolicy.performanceCookiesEnabled || false;
  let isEnabled = true; // default to tracking enabled
  if (oneTrustActive) {
    isEnabled = performanceCookiesEnabled; // if oneTrustActive tracking enabled if performanceCookiesEnabled = true
  }
  return isEnabled;
};

const ensightenTrackingSetup = () => {
  const vars = window.trackingVariables;
  // Set the device to presentationtemplate
  vars.presentationtemplate = TurnerToons.device == 'smartphone' ? 'mobile web' : TurnerToons.device;
  vars.content_title = vars.content_title ? vars.content_title : document.title.toLowerCase(); // exclude this from homepage, gamespage, videospage, clubpage, appspage

  if (TurnerToons.device != 'desktop') {
    if (window.orientation == 0 || window.orientation == 180) {
      vars.screenorientation = 'mobile|potrait';
    } else {
      vars.screenorientation = 'mobile|landscape';
    }

    window.addEventListener('resize', function () {
      if (window.orientation == 0 || window.orientation == 180) {
        vars.screenorientation = 'mobile|potrait';
      } else {
        vars.screenorientation = 'mobile|landscape';
      }
    });
  }

  if (!trackingIsEnabled()) {
    return;
  }

  const pageId = (window.gallery_config && window.gallery_config.pageId) || 'unknown';
  log.enabled && console.log(`${log.prep} ensightenTrackingSetup | trackingIsEnabled = true | pageId ${pageId}`, log.clr);
  switch (pageId) {
    case 'gameplay':
      triggerTracking('gamepageview');
      break;
    case 'web_game_category':
      triggerTracking('gamecategorypageview');
      break;
    default:
      triggerTracking('pageview');
  }

  // If there are carousel items
  if ($('.shows_carousel_wrapper .swiper-wrapper .swiper-slide').length > 0) {
    $('.shows_carousel_wrapper .swiper-wrapper .swiper-slide a').each(function (index, item) {
      $(item).on('click', function () {
        const english_name = $(this).data('englishname');
        triggerTracking('interaction', { english_name });
      });
    });
  }

  // Featured Items in the header that are game content types -- When set to maincarousel
  if ($('.main_carousel .carousel_wrapper .carousel_item').length > 0) {
    if ($('.main_carousel .carousel_wrapper .carousel_item.content_item--web_game').length > 0) {
      $('.main_carousel .carousel_wrapper .carousel_item.content_item--web_game').each(function (index, item) {
        const trackData = {
          english_name: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        const parent = $(this).parent();
        parent.on('click', function () {
          triggerTracking('interactionGameClick', trackData);
        });
      });
    }
  }

  // Related Items under the header that are game content types
  if ($('.carousel_widget.related').length > 0) {
    if ($('.carousel_widget.related .carousel_wrapper .content_item.content_item--web_game').length > 0) {
      $('.carousel_widget.related .carousel_wrapper .content_item.content_item--web_game').each(function (index, item) {
        const trackData = {
          english_name: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        const parent = $(this).parent();
        parent.on('click', function () {
          triggerTracking('interactionGameClick', trackData);
        });
      });
    }
  }

  // Items under the header that are Show video content types
  if ($('.carousel_widget.pos_show_videos').length > 0) {
    if ($('.carousel_widget.pos_show_videos .carousel_wrapper .content_item.content_item--video').length > 0) {
      $('.carousel_widget.pos_show_videos .carousel_wrapper .content_item.content_item--video').each(function (index, item) {
        const trackData = {
          english_name: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        const parent = $(this).parent();
        parent.on('click', function () {
          triggerTracking('interactionVideoClick', trackData);
        });
      });
    }
  }

  // Featured Items in the header that are game content types
  if ($('.featured_header .featured_header_item').length > 0) {
    if ($('.featured_header .featured_header_item a.content_item--web_game').length > 0) {
      $('.featured_header .featured_header_item a.content_item--web_game').each(function (index, item) {
        const trackData = {
          english_name: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        $(item).on('click', function () {
          triggerTracking('interactionGameClick', trackData);
        });
      });
    }
    if ($('.featured_header .featured_header_item a.content_item--video').length > 0) {
      $('.featured_header .featured_header_item a.content_item--video').each(function (index, item) {
        const trackData = {
          english_name: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        $(item).on('click', function () {
          triggerTracking('interactionVideoClick', trackData);
        });
      });
    }
    if ($('.featured_header .featured_header_item a.content_item--manual').length > 0) {
      $('.featured_header .featured_header_item a.content_item--manual').each(function (index, item) {
        const trackData = {
          title: $(this).data('title') || 'nvs',
          desc: $(this).data('description') || 'nvs',
        };
        $(item).on('click', function () {
          triggerTracking('interactionFeaturedClick', trackData);
        });
      });
    }
  }

  // Featured Items :: footer
  if ($('.featured_wrapper .featured_item').length > 0) {
    if ($('.featured_wrapper .featured_item .content_item.content_item--legacy.content_item--web_game').length > 0) {
      $('.featured_wrapper .featured_item .content_item.content_item--legacy.content_item--web_game').each(function (index, item) {
        const trackData = {
          english_name: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        $(item).on('click', function () {
          triggerTracking('interactionGameClick', trackData);
        });
      });
    }
    if ($('.featured_wrapper .featured_item .content_item.content_item--legacy.content_item--video').length > 0) {
      $('.featured_wrapper .featured_item .content_item.content_item--legacy.content_item--video').each(function (index, item) {
        const trackData = {
          english_name: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        $(item).on('click', function () {
          triggerTracking('interactionVideoClick', trackData);
        });
      });
    }
  }

  // App store links
  if ($('#individual-app-page .app-store-links__link').length > 0) {
    $('#individual-app-page .app-store-links__link a').each(function (index, item) {
      $(item).on('click', function () {
        const { title, nameEnglish } = getContentData();
        triggerTracking('interactionAppClick', { nameEnglish, title });
      });
    });
  }

  // Editorial content block clicks
  if ($('.editorials .carousel_item').length > 0) {
    if ($('.editorials .carousel_item a.content_item--web_game').length > 0) {
      $('.editorials .carousel_item a.content_item--web_game').each(function (index, item) {
        const trackData = {
          english_name: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        $(item).on('click', function () {
          triggerTracking('interactionGameClick', trackData);
        });
      });
    }
    if ($('.editorials .carousel_item a.content_item--video').length > 0) {
      $('.editorials .carousel_item a.content_item--video').each(function (index, item) {
        const trackData = {
          english_name: $(this).data('englishname'),
          englishshowname: $(this).data('englishshowname'),
        };
        $(item).on('click', function () {
          triggerTracking('interactionVideoClick', trackData);
        });
      });
    }
    if ($('.editorials .carousel_item a.content_item--manual').length > 0) {
      $('.editorials .carousel_item a.content_item--manual').each(function (index, item) {
        const trackData = {
          title: $(this).data('title') || 'nvs',
          desc: $(this).data('description') || 'nvs',
        };
        $(item).on('click', function () {
          triggerTracking('interactionFeaturedClick', trackData);
        });
      });
    }
  }
};

const getTimeZone = () => {
  const offset = new Date().getTimezoneOffset();
  const o = Math.abs(offset);
  return offset === 0 ? '' : (offset < 0 ? '+' : '-') + Math.floor(o / 60);
};

const getVersionDateFormat = () => {
  // format expected (DOW: Day of Week) - "<web"|{DOW}|{Month}|{Day}|{Year}|HH:MM:SS}|{TimeZone}>
  // "web|monday|01|09|2020|16:00:00|UTC"
  const date = new Date();

  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const dow = days[date.getDay()];
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours().toString();
  const mins = date.getMinutes().toString();
  const secs = date.getSeconds().toString();
  const time = `${hour.length == 1 ? '0' : ''}${hour}:${mins.length === 1 ? '0' : ''}${mins}:${secs.length == 1 ? '0' : ''}${secs}`;
  return `web|${dow}|${month}|${day}|${year}|${time}|UTC${getTimeZone()}`;
};

const triggerTracking = (type, data) => {
  if (!trackingIsEnabled()) {
    return;
  }

  // const { feedcode, previous_page, screenorientation, pageview_event, ...baseData } = window.trackingVariables;
  const baseData = { ...window.trackingVariables };
  baseData.content_title = baseData.content_title === '' || baseData.content_title === null ? 'nvs' : baseData.content_title;

  console.log('+++++++++ tracking', baseData, type, data);
  baseData.version = getVersionDateFormat();

  switch (type) {
    case 'pageview':
      baseData.pageview_event = 1;
      return trackPageview({ baseData, log });
    case 'gamepageview':
      return trackGamePageview({ baseData, log });
    case 'gamecategorypageview':
      return trackGameCategoryPageview({ baseData, log });
    case 'interaction':
      return trackInteraction({ type: 'interactionClick', baseData, overrideData: data, log });
    case 'interactionAppClick':
      return trackInteraction({ type: 'appClick', baseData, overrideData: data, log });
    case 'interactionGameClick':
      return trackInteraction({ type: 'gameClick', baseData, overrideData: data, log });
    case 'interactionFeaturedClick':
      return trackInteraction({ type: 'featuredClick', baseData, overrideData: data, log });
    case 'interactionVideoClick':
      return trackInteraction({ type: 'videoClick', baseData, overrideData: data, log });
  }
};

export { ensightenTrackingSetup };
