/**
 * Lazy Shows Carousel
 */
import SwiperCore, { Navigation, Lazy } from 'swiper';
import { Swiper } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/lazy/lazy.scss';

SwiperCore.use([Navigation, Lazy]);

const ShowsCarouselLazy = function (carouselIsSticky) {
  this.window = $(window);
  this.showsCarouselMarker = $('.shows_carousel_marker');
  this.showsCarousel = $('.shows_carousel');
  this.init(carouselIsSticky);
};

ShowsCarouselLazy.prototype = {
  init: function (carouselIsSticky) {
    if (carouselIsSticky) {
      this.window.on('scroll', () => {
        this.setStickyCarousel();
      });
    }

    $('.shows_carousel_wrapper a').each(function (index, value) {
      let delay = index * 0.06;
      $(this)
        .css('-webkit-transition-delay', delay + 's')
        .css('transition-delay', delay + 's');
    });

    setTimeout(function () {
      $('.shows_carousel').addClass('animate');
    }, 200);

    // swiper-container class name is used on every swiper component
    // so these options below were having a knock on effect to each one
    // So applied a unique name
    const swiper = new Swiper('.carousel-swiper-container', {
      preloadImages: false,
      lazy: true,
      direction: 'horizontal',
      loop: false,
      slidesPerView: 3, //'auto',
      slidesPerGroup: 3,
      navigation: {
        nextEl: '.arrow-right',
        prevEl: '.arrow-left',
      },
      breakpoints: {
        3500: {
          slidesPerView: 25,
          spaceBetween: 10,
        },
        2000: {
          slidesPerView: 20,
          spaceBetween: 10,
        },
        1441: {
          slidesPerView: 13,
          spaceBetween: 10,
        },
        1168: {
          slidesPerView: 10,
          spaceBetween: 0,
        },
        640: {
          slidesPerView: 8,
          spaceBetween: 0,
        },
        480: {
          slidesPerView: 7,
          spaceBetween: 0,
        },
        320: {
          slidesPerView: 5,
          spaceBetween: 0,
        },
      },
    });
  },

  setStickyCarousel: function () {
    if (this.showsCarouselMarker.offset().top - this.window.scrollTop() <= 0) {
      this.showsCarouselMarker.css({ height: this.showsCarousel.outerHeight() });
      this.showsCarousel.addClass('sticky');
    } else {
      this.showsCarouselMarker.css({ height: '' });
      this.showsCarousel.removeClass('sticky');
    }
  },
};
export { ShowsCarouselLazy };
