import { GAMECATEGORY_TEMPLATE, GAMEPLAY_TEMPLATE, getTrackingArray } from 'modules/ensighten_tracking/constants/ensightenConstants';
import { getContentData } from 'modules/ensighten_tracking/utils';

export const trackGamePageview = ({ baseData, log }) => {
  const { title, nameEnglish, showNameEnglish, gameCategory } = getContentData();
  let pageData = { ...baseData, ...GAMEPLAY_TEMPLATE };
  pageData.game_title = title;
  pageData.english_name = nameEnglish;
  pageData.franchise = showNameEnglish;
  pageData.game_category = gameCategory;
  pageData.content_type = 'game detail';
  log.enabled && console.log(`${log.prep} trackGamePageview`, log.clr, pageData);
  getTrackingArray().push({ type: 'pageview', data: pageData });
};

export const trackGameCategoryPageview = ({ baseData, log }) => {
  const { data = {} } = window.gameCategory;
  const pageData = { ...baseData, ...GAMECATEGORY_TEMPLATE };
  pageData.game_category = data.englishname ? data.englishname.toLowerCase() : 'nvs';
  log.enabled && console.log(`${log.prep} trackGameCategoryPageview`, log.clr, pageData);
  getTrackingArray().push({ type: 'pageview', data: pageData });
};

const mergeCategoryData = ({ baseData }) => {
  const { web_game_category = {} } = window.gameCategory;
  baseData.game_category = web_game_category?.name.toLowerCase() || 'nvs';
  baseData.gamecategorypage_event = 1;
};

const mergeGameData = ({ baseData }) => {
  const { title, nameEnglish, showNameEnglish, gameCategory } = getContentData();
  baseData.subsection = `games|${title}`;
  baseData.content_type = 'game detail';
  baseData.game_title = title;
  baseData.english_name = nameEnglish;
  baseData.gamedetailview_event = 1;
  baseData.game_category = gameCategory;

  /*
   * https://jollywise.atlassian.net/browse/TAI-258
   * content_title is clicked content, not current page title
   */
  baseData.content_title = title || nameEnglish;
};

const mergeVideoData = ({ baseData }) => {
  const { title, nameEnglish, showNameEnglish } = getContentData();
  baseData.subsection = `video|${title}`;
  baseData.content_type = 'video detail';
  baseData.video_title = title;
  baseData.english_name = nameEnglish;
  baseData.videodetailview_event = 1;

  /*
   * https://jollywise.atlassian.net/browse/TAI-258
   * content_title is clicked content, not current page title
   */
  baseData.content_title = title || nameEnglish;
};

export const trackPageview = ({ baseData, log }) => {
  // dont track pageview if we are in the letscreate app - as this is handled by the letscreate app
  const friendlyURLS = TurnerToons.FrontEndFriendlyURLs;
  const locationHash = window.location.hash;
  const isSectionLetsCreate = window.location.pathname.includes(friendlyURLS.letscreate);
  const hasLetsCreateHash = isSectionLetsCreate && locationHash && (locationHash.includes('submit') || locationHash.includes('index') || locationHash.includes('creation'));
  if (hasLetsCreateHash) {
    return;
  }

  const { section, subsection } = baseData;
  switch (section) {
    case 'home':
      baseData.content_type = 'home page';
      break;
    case 'editorial':
      baseData.section = 'editorials';
      baseData.subsection = 'editorials|main';
      baseData.content_type = 'editorials page';
      break;
    case 'shows':
      baseData.content_type = 'shows page';
      break;
    case 'win':
    case 'campaigns':
      baseData.section = 'campaigns';
      baseData.subsection = 'campaigns|main';
      baseData.content_type = 'campaigns page';
      break;
    case 'videos':
      baseData.content_type = 'video page';
      if (!subsection.includes('|main')) {
        mergeVideoData({ baseData });
      }
      break;
    case 'games':
      baseData.content_type = 'games page';
      baseData.game_category = 'nvs';
      if (subsection.includes('|category')) {
        mergeCategoryData({ baseData });
      } else if (!subsection.includes('|main')) {
        mergeGameData({ baseData });
      }

      baseData.url = window.location.hostname + window.location.pathname;
      baseData.screenorientation = window.trackingVariables.screenorientation;

      break;
    default:
      break;
  }
  // TR-41
  // if (subsection.includes('|main') && baseData.content_type !== 'home page') {
  //   // baseData.content_type = 'section front';
  // }

  if ($('#character-page').length > 0 || $('#individual-app-page').length > 0) {
    const { title, nameEnglish } = getContentData();

    baseData.content_title = title;
    baseData.english_name = nameEnglish;
  }
  log.enabled && console.log(`${log.prep} trackPageview`, log.clr, baseData);
  getTrackingArray().push({ type: 'pageview', data: baseData });
};
