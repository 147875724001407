import SwiperCore, { Navigation, Lazy } from 'swiper';
import { Swiper } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/lazy/lazy.scss';

SwiperCore.use([Navigation, Lazy]);

class FeedBlockLetscreate {
  constructor(options) {
    this.wrapper = options.wrapper;
    this.el = options.el;
    this.apiTemplate = options.apiURL;
    this.apiAction = options.apiAction;
    this.apiURL = options.apiURL ? options.apiURL.replace('api/{action}', this.apiAction) : '';
    this.likesApiURL = options.apiURL ? options.apiURL.replace('{action}', 'rate') : '';
    this.itemLinkBaseURL = options.itemLinkBaseURL ? options.itemLinkBaseURL : '';
    this.url_fragment = options.url_fragment;
    this.itemTemplate = options.itemTemplate ? options.itemTemplate.innerHTML : '';
    this.feedItemType = options.feedItemType;
    this.feedItemName = options.feedItemName;
    this.altTitle = options.altTitle;
    this.titleDomEl = options.titleDomEl;
    this.limit = options.limit;
    this.target = options.target;
    this.fragment = '';
    this.log = options.log;
  }

  _kFormat(num) {
    if (isNaN(num)) {
      num = parseInt(num);
    }
    return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
  }

  _parseFeedData(response, fragment) {
    const feed = response[this.feedItemName];
    const placeholderImage = `${TurnerToons.staticURL}base/images/placeholder_feedblock.svg`;
    for (let i = 0; i < feed.length; i++) {
      const feedItem = feed[i];
      if (i < this.limit) {
        const { data = {}, ratings = [] } = feedItem;
        let itemString = this.itemTemplate
          .replace('{url}', this.itemLinkBaseURL + this.url_fragment + feedItem.external_id)
          .replace('{image}', response.base_url + feedItem.thumb_file)
          .replace('{placeholder}', placeholderImage)
          .replace('{title}', data.first_name || '')
          .replace('{description}', data.age || '')
          .replace('{external_id}', feedItem.external_id)
          .replace('{content_media_type}', feedItem.media_type);

        const res = ratings.reduce(
          (prevVal, elem) => {
            if (elem.rating_id && elem.rating_id === '0') {
              prevVal.likes = parseInt(elem.rating_count);
            }
            if (elem.rating_id && elem.rating_id === '1') {
              prevVal.views = parseInt(elem.rating_count);
            }
            return prevVal;
          },
          { likes: 0, views: 0 }
        );
        itemString = itemString.replace('{likes}', this._kFormat(res.likes));
        itemString = itemString.replace('{formattedWatchCount}', this._kFormat(res.views));
        fragment += itemString;
      } else {
        break;
      }
    }
    return fragment;
  }

  getFeedData() {
    // eslint-disable-next-line new-cap
    let def = $.Deferred();
    $.ajax({
      url: this.apiURL,
      method: 'GET',
      dataType: 'json',
    })
      .done((response) => {
        if (response.result === 'SUCCESS') {
          this.log.enabled && console.info(`${this.log.prep} FeedBlockLetscreate getFeedData response : ${this.feedItemType} | ${response.result}`, this.log.clr);
          this.fragment = this._parseFeedData(response, '');
          def.resolve({ result: 'SUCCESS', type: this.feedItemType });
        } else {
          this.log.enabled && console.warn(`${this.log.prep} FeedBlockLetscreate getFeedData error : ${this.feedItemType} | ${response.result} | ${response.reason}`, this.log.clr);
          def.reject({ result: response.result, type: this.feedItemType, response: response });
        }
      })
      .fail((e) => {
        this.log.enabled && console.warn(`${this.log.prep} FeedBlockLetscreate getFeedData FAIL : ${this.feedItemType}`, this.log.clr, e);
        def.reject({ type: this.feedItemType, status: e.status, statusText: e.statusText });
      });
    return def.promise();
  }

  getTrendingFeedData() {
    this.feedItemType = 'trending';
    this.feedItemName = 'entries';
    // console.info('FeedBlockLetscreate.getFeedData', this.feedItemType, 'limit', this.limit);
    $(this.titleDomEl).html(this.altTitle);
    $.ajax({
      url: this.apiTemplate.replace('{action}', 'get_approved_entry_details'),
      data: { offset: 0, limit: 9 },
      method: 'POST',
      dataType: 'json',
    })
      .done((response) => {
        if (response.result === 'SUCCESS') {
          this.log.enabled && console.info(`${this.log.prep} FeedBlockLetscreate getTrendingFeedData response : ${this.feedItemType} | ${response.result}`, this.log.clr);
          this.fragment = this._parseFeedData(response, '');
          this.renderFeedData();
        } else {
          this.log.enabled && console.warn(`${this.log.prep} FeedBlockLetscreate getTrendingFeedData error : ${this.feedItemType} | ${response.result} | ${response.reason}`, this.log.clr);
        }
      })
      .fail(function (e) {
        this.log.enabled && console.warn(`${this.log.prep} FeedBlockLetscreate getTrendingFeedData FAIL : ${this.feedItemType}`, this.log.clr, e);
      });
  }

  renderFeedData() {
    if (this.fragment) {
      $(this.wrapper).css({ display: 'block' });
      this.el.append(this.fragment);
      const direction = window.innerWidth <= 320 ? 'vertical' : 'horizontal';
      this.renderCarousel(direction);
      const options = {
        el: this.el,
        like_api_url: this.likesApiURL,
        ratings_id: FeedBlockLetscreate.APP_RATINGS_ID,
        like_cookie_id: FeedBlockLetscreate.LIKED_COOKIE,
      };
      return options;
    }
  }

  renderCarousel(direction) {
    const breakpoints =
      direction === 'vertical'
        ? {}
        : {
            767: { slidesPerView: 3 }, // when window width is >= 767 px
            320: { slidesPerView: 2 }, // when window width is >= 320 px
            1: { slidesPerView: 1 }, // when window width is >= 1 px
          };
    const swiperOptions = {
      spaceBetween: 20,
      // watchOverflow: true,
      preloadImages: false,
      lazy: true,
      direction,
      loop: false,
      slidesPerView: 3,
      slidesPerGroup: 1,
      navigation: {
        nextEl: `.${this.target} .nav_right`,
        prevEl: `.${this.target} .nav_left`,
      },
      breakpoints,
    };

    const container = `.carousel_widget.${this.target} .swiper-container`;
    switch (direction) {
      case 'horizontal':
        $(container).removeClass('swiper-container-vertical');
        $(`${container} .swiper-slide`).each(function () {
          $(this).css('display', '');
        });
        this.carousel = new Swiper(`.carousel_widget.${this.target} .carousel_area`, swiperOptions);
        this.log.enabled && console.log(`${this.log.prep}  FeedBlockLetscreate.renderCarousel | target : ${this.target}`, this.log.clr, this.carousel);
        break;
      case 'vertical':
        // destroy swiper and display 3 items
        this.carousel && this.carousel.destroy(true, true);
        $(container).addClass('swiper-container-vertical');
        $(`${container} .swiper-slide`).each(function (index) {
          if (index < 3) {
            const img = $(this).find('img.feedblock-img');
            const imgSrc = $(img).data('src');
            $(img).attr('src', imgSrc);
          } else {
            $(this).css('display', 'none');
          }
        });
        break;
    }
  }

  handleResize(direction) {
    this.renderCarousel(direction);
  }
}

FeedBlockLetscreate.APP_RATINGS_ID = 0;
FeedBlockLetscreate.LIKED_COOKIE = 'letscreate_likes';
export default FeedBlockLetscreate;
